<template>
    <v-container class="full-height">
        <v-col class="full-height">
            <v-alert v-if="error" type="error">{{ error }}</v-alert>
            <template v-else-if="metaCampaign">
                <CampaignCard
                    :title="metaCampaign.title"
                    :description="metaCampaign.description"
                    :schema="schema"
                    :values="values"
                    :responseId="metaCampaign.responseId"
                    :options="metaCampaign.options"
                    @submit="onSubmit"
                />
            </template>
            <v-overlay v-else :absolute="false">
                <JumpingDots />
            </v-overlay>
        </v-col>
        <v-overlay :absolute="false" v-if="submitting">
            <JumpingDots v-if="submitting === 'sending'" />
            <v-col v-if="submitting === 'success'">
                <v-row align="center" justify="center">
                    <v-icon x-large color="primary">mdi-check-outline</v-icon>
                </v-row>
                <v-row align="center" justify="center">
                    <h2>Thank you for your submission</h2>
                </v-row>
            </v-col>
        </v-overlay>
    </v-container>
</template>

<script>
import _set from 'lodash/set'

import { functions } from '@/firebase'

export default {
    components: {
        CampaignCard: () => import('@/components/CampaignCard'),
        JumpingDots: () => import('@/components/loaders/JumpingDots'),
    },
    props: {
        metaCampaignDefinition: {
            type: Object,
            required: true,
        },
        query: {
            type: Object,
        },
    },
    data() {
        return {
            loading: true,
            submitting: null,
            error: null,
            metaCampaign: null,
            values: {},
            locationWatchId: null,
            position: null,
        }
    },
    async created() {
        await this.loadMetaCampaign()
    },
    methods: {
        async loadMetaCampaign() {
            // Get URL query parameters and insert them into this.values, i.e. prefilled values.
            for (const [key, value] of Object.entries(this.query)) {
                _set(this.values, key, value)
            }

            if ('geolocation' in navigator) {
                this.locationWatchId = navigator.geolocation.watchPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords
                        this.position = { latitude, longitude }
                    }
                )
            }

            try {
                const callable = functions.httpsCallable('createMetaCampaign')
                const { data: metaCampaign } = await callable({
                    metaCampaignId: this.metaCampaignDefinition.id,
                })
                this.metaCampaign = metaCampaign
            } catch (err) {
                this.error = `Error while creating campaign (${err.message})`
            } finally {
                this.loading = false
            }
        },
        async onSubmit(data) {
            this.submitting = 'sending'
            const { userId } = this.user
            const submission = {
                metaCampaignId: this.metaCampaign.metaCampaignId,
                responseId: this.metaCampaign.responseId,
                userId,
                position: this.position,
                data,
            }

            try {
                const callable = functions.httpsCallable('campaignSubmit')
                var response = await callable(submission)
            } catch (err) {
                // It would be so much nicer to display this as an error notification rather than
                // a transient error message.
                this.submitting = null
                this.error = err.message
                return
            }

            this.submitting = 'success'
            const { followOnMetaCampaignId } = response.data
            if (followOnMetaCampaignId) {
                // Immediately redirect to the follow-on meta campaign.
                this.$router.push(`/campaign/${followOnMetaCampaignId}`)
            } else {
                // Wait for some time to show the success symbol.
                await new Promise((resolve) => setTimeout(resolve, 2000))
                // Redirect to the main view.
                this.$router.push('/main')
            }
            this.submitting = null
        },
    },
    beforeUnmount() {
        if (this.locationWatchId) {
            // Clear the location watch, if there is one.
            navigator.geolocation.clearWatch(this.locationWatchId)
        }
    },
    computed: {
        schema() {
            return this.metaCampaign.schema || null
        },
        user() {
            const { userId } = this.$store.getters['auth/getUser']
            return {
                userId,
            }
        },
    },
}
</script>

<style scoped>
div.full-height {
    height: 100%;
}
</style>

